
import { computed, defineComponent, onMounted, reactive, ref, Ref } from 'vue'
import { Person } from '@/models/person/classes/Person'
import CommentBox from '@/components/commons/boxes/CommentBox/CommentBox.vue'
import { injectStrict } from '@/utils/injectStrict'
import { PersonKey } from '../keys'
import { useImageLoader } from '@/hooks/useImageLoader'
import { useCountriesStore } from '@/store/modules/commons/countries-store'
import { CommentAPI } from '@/api/comments.api'
import { CommentTarget } from '@/models/course/enums'
import { Comment } from '@/models/comments'
import { useCommentBoxEventsHandler } from '@/hooks/useCommentBoxEventsHandler'
import { PlatformUserRoleType, PersonRoles } from '@/models/person/enums'
import CoursesMultipleSelector from '@/components/commons/selectors/CoursesMultipleSelector.vue'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
export default defineComponent({
  components: {
    CommentBox,
    CoursesMultipleSelector
  },
  setup () {
    const person = injectStrict<Ref<Person | null>>(PersonKey)
    const { loading: loadingAvatar } = useImageLoader(person.value?.avatar || '')
    const countriesStore = useCountriesStore()
    const countries = computed(() => countriesStore.countries)
    const comments = ref<Comment[]>([])
    const loading = ref(true)
    const coursePlans = ref<CoursePlan[]>([])
    const careerCourses = ref([])
    const { handleSave: onCommentBoxSaveEvent } = useCommentBoxEventsHandler(comments)
    const formData = reactive({
      ...person.value
    })
    const isStaff = computed<boolean>(() => person.value?.rol === PlatformUserRoleType.Staff)
    const isStudent = computed<boolean>(() => person.value?.rol === PlatformUserRoleType.Student || Array.from(person.value?.roles ?? []).includes(PersonRoles.Student))
    onMounted(async () => {
      try {
        loading.value = true
        const res = await CommentAPI.findAllComments(CommentTarget.Person, person.value?.id || '')
        if (person.value?.rol === PlatformUserRoleType.Staff) {
          coursePlans.value = await CoursePlanAPI.findAll()
        }
        comments.value = res
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })

    return {
      person,
      loading,
      loadingAvatar,
      formData,
      countries,
      comments,
      isStaff,
      isStudent,
      coursePlans,
      careerCourses,
      onCommentBoxSaveEvent
    }
  }
})
