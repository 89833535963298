import { ref, Ref } from 'vue'

export const useImageLoader = (
  src: string
): {
  loading: Ref<boolean>
  srcRef: Ref<string | null>
} => {
  const loading = ref<boolean>(true)
  const srcRef = ref<string | null>(null)
  const image = new Image()
  image.onload = function () {
    srcRef.value = image.src
    loading.value = false
  }
  if (!src) {
    loading.value = false
  } else {
    image.src = src
  }
  return {
    loading,
    srcRef
  }
}
